import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getCroppedImg, getRotatedImage } from './canvasUtils'


const styles = theme => ({
    root : {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width:"90%"
    },
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: 600,
      background: '#333',
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      width:"100%",
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    sliderContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      marginLeft: 16
    },
  })


const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }

  
  
  const ImgCropper = ({ classes, file, handleUpload }) => {
    
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
  
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])
  
    const uploadCroppedImage = async () => {
      try {
        const croppedImage = await getCroppedImg(
          file,
          croppedAreaPixels,
          rotation
        )
        console.log('donee', { croppedImage })
        
        handleUpload(croppedImage)
      } catch (e) {
        console.error(e)
      }
    }
  
    const onClose = useCallback(() => {
      setCroppedImage(null)
    }, [])
  
    // const onFileChange = async e => {
    //   if (e.target.files && e.target.files.length > 0) {
    //     const file = e.target.files[0]
    //     let imageDataUrl = await readFile(file)
  
    //     // apply rotation if needed
    //     const orientation = await getOrientation(file)
    //     const rotation = ORIENTATION_TO_ANGLE[orientation]
    //     if (rotation) {
    //       imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
    //     }
  
    //     setImageSrc(imageDataUrl)
    //   }
    // }
  
    return (
      
        <div className={classes.root}>
            <div className={classes.cropContainer}>
              <Cropper
                image={file}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={478/512}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Rotation
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </div>
              <Button
                onClick={uploadCroppedImage}
                variant="contained"
                color="primary"
                classes={{ root: classes.cropButton }}
              >
                Crop and save
              </Button>
            </div>            
          </div>
        )
  }
  

  
 export default withStyles(styles)(ImgCropper)
  