import * as React from 'react';
import { FC  } from 'react';

import {    
    Edit,
    SimpleForm,
    TextInput,
    required,
    NumberInput
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { EditComponentProps } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import PairInput from '../components/PairInput'


const drawerWidth = 600;
const useStyles = makeStyles({

    root: {
               
    },    
    form : {
        width: '60%',
    }
})

const validateLevelCreation = (values:any) => {
    const errors:any = {};
    // if (values.mode === modeType.PREDICTIVE && (values.answers.length < 7 || values.answers.includes(""))) {
    //     errors.answers = ['Must have at least 7 fullfilled answers'];
    // }

    // var format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
 

    // if(values.answers.some((a:string) => {return format.test(a)})) {
    //     errors.answers = ['Found special characters (parmi !@#$%^&*()_+=[]{};\':"\\|,.<>\/?] )  in anwsers']
    // }

    return errors
};




const LevelEdit: FC<EditComponentProps> =  ({ permissions, ...props }) => {
    const classes = useStyles();        

    return (
        
        <Edit  className={classes.root} {...props} >
            <SimpleForm className={classes.form} validate={validateLevelCreation}>                
               
                <NumberInput source="rank" label="Index"/> 
                <TextInput autoFocus source="title" validate={required()} />
                <NumberInput source="difficulty" label="Difficulty" defaultValue={1}/> 
                <PairInput source="pair1" label="Pair 1" />
                <PairInput source="pair2" label="Pair 2" />
                <PairInput source="pair3" label="Pair 3" />
                <TextInput source="credits" label="Credits"  />
                <TextInput source="sources" label="Sources"  />


                
           
            </SimpleForm>
        </Edit>
        
    );
};

export default LevelEdit;
