import * as React from 'react';
import { FC,  useState, useEffect } from 'react';
import { useRefresh } from 'react-admin';
import {    
    Create,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    useRedirect,
    useNotify
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import PairInput from '../components/PairInput'
import { CreateComponentProps } from '../types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({

    root: {
               
    },    
    form : {
        width: '60%',
    }
})


const findRankMax = async (props:any) => {
    const access_token= localStorage.getItem('token')
    const responseJson = await fetch(`${process.env.REACT_APP_API_URL}${props.basePath}/id/last?access_token=${access_token}`).then((response) => response.json()).catch((e) => {return {rank : -1}})
    return responseJson.rank
}

const validateLevelCreation = (values:any) => {
    const errors:any = {};
    // if (values.mode === modeType.PREDICTIVE && (values.answers.length < 7 || values.answers.includes(""))) {
    //     errors.answers = ['Must have at least 7 fullfilled answers'];
    // }

    // var format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
 

    // if(values.answers && values.answers.some((a:string) => {return format.test(a)})) {
    //     errors.answers = ['Found special characters (parmi !@#$%^&*()_+=[]{};\':"\\|,.<>\/?] )  in anwsers']
    // }

    return errors
};


const LevelCreate: FC<CreateComponentProps> = ({ permissions, ...props }:any) => {
    
    const classes = useStyles();
    const [maxRank, setMaxRank] = useState(0);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = ({ data }:any) => {
        notify(`New level saved`)
        redirect(props.basePath);
        refresh();
    };

    useEffect( () => {
        findRankMax(props).then(max => {
            max++
            setMaxRank(max)
            refresh()
        })        
    }, [])    
    return (
        
        <Create {...props} className={classes.root} onSuccess={onSuccess}>
            <SimpleForm className={classes.form} validate={validateLevelCreation}>                
               
                <NumberInput source="rank" label="Index" defaultValue={maxRank}/> 
                <TextInput source="title" validate={required()} />
                <NumberInput source="difficulty" label="Difficulty" defaultValue={1}/> 
                <PairInput source="pair1" label="Pair 1" />
                <PairInput source="pair2" label="Pair 2" />
                <PairInput source="pair3" label="Pair 3" />
                <TextInput source="credits" label="Credits"  />
                <TextInput source="sources" label="Sources"  />

               

            </SimpleForm>
        </Create>
        
    );
};

export default LevelCreate;

