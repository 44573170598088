
import React from 'react';
import {TextField, BooleanField, BooleanInput, Toolbar, SaveButton, DeleteButton, List, Datagrid, DateField, EditButton, TextInput, Edit, Create, SimpleForm} from 'react-admin';

import { withStyles } from '@material-ui/core'


const required = (message = 'Obligatoire') =>
    value => value ? undefined : message;


const CountryTitle = ({ country }) => {
    return <span>Country {country ? `"${country.idLang}-${country.idCountry}"` : ''}</span>;
};



const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
    </Toolbar>
));


const CountrySimpleFormEdit = (props) => {    
    return (
    <SimpleForm {...props} >   
        <TextInput source="idLang" label="Lang"/> 
        <TextInput source="idCountry" label="Country"/> 
        <BooleanInput source="defaultLang" defaultValue="true" label="Default lang"/>
    </SimpleForm>
    )
};


const CountryList = props => {
    return (
    <List sort={{ field: 'rank', order: 'DESC' }} perPage={10} {...props}>
        <Datagrid  rowClick="edit">
            <TextField source="idLang" label="Lang"/>
            <TextField source="idCountry" label="Country"/>
            <BooleanField source="defaultLang" label="Default Lang"/>
            <DateField source="updatedAt" label="Modifié le"/>
            <EditButton/>
        </Datagrid>
    </List>
    );
};

const CountryEdit = props => (
    <Edit title={<CountryTitle/>} {...props}>        
        <CountrySimpleFormEdit toolbar={<CustomToolbar />}/>        
    </Edit>
);

const CountryCreate = props => (
    <Create title={<CountryTitle/>}{...props}>        
            <CountrySimpleFormEdit redirect="list"/> 
    </Create>
);


export default {
     create: CountryCreate,
     edit: CountryEdit,
     list: CountryList,

};
