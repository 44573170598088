import * as React from 'react';
import { FC , useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';



const useStyles = makeStyles({
    root: { display: 'flex',
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            margin: '1em',
            padding: "10px",
            minWidth: "350px", 
            height: "100px"
        },
    image: {
        width: "100px",
    }
});

interface PairDisplayProps {
    record?: any;
    source?: any;
}

const PairDisplay: FC<PairDisplayProps> = (props) => {

    
    const classes = useStyles();
    const source = props.source
    const record = props.record
    if (!record) return null;
    
    return (
        <>
        <Paper elevation={3} className={classes.root}>
                <img className={classes.image} src={record.pair1.imageTop} />
                <img className={classes.image} src={record.pair2.imageTop}/>
                <img className={classes.image} src={record.pair3.imageTop}/>            
        </Paper>
        <Paper elevation={3} className={classes.root}>            
                <img className={classes.image} src={record.pair1.imageBottom} />
                <img className={classes.image} src={record.pair2.imageBottom}/>
                <img className={classes.image} src={record.pair3.imageBottom}/>    
      
        </Paper>
        </>
    );
};

export default PairDisplay;