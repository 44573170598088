import * as React from 'react';
import { FC , useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import { FieldRenderProps } from "react-final-form";
import Files from "react-butterfiles";
import ImgDialog from './ImgDialog'
import {uploadToApi} from '../simpleRestProvider'

const useStyles = makeStyles({
    root: { display: 'flex',
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            margin: '1em',
            padding: "10px",
            width: "800px", 
            height: "220px"
        },
});

interface PairInputProps {
    record?: any;
    source?: any;
    label: string;
}


function readFile(file:File) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
}

const token = localStorage.getItem('token');

type Props = FieldRenderProps<string, any>;
const FileInputImageAdapter: FC<Props> = ({ input: { onChange, value }, label, ...rest }) => {
    
    const [file, setFile] = useState<any>()
    const [cropper, setCropper] = useState(false)
    const [fileURL, setFileURL] = useState(value)

    const handleChange = async (files:any) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file.src.file)
            setFile(imageDataUrl)
        }        
    }

    const handleUpload = async (croppedImage:any) => {
        
        const imageURL = await uploadToApi(process.env.REACT_APP_API_URL?process.env.REACT_APP_API_URL:'http://localhost:9000', token, croppedImage)        
        setCropper(false)
        setFile(null)
        setFileURL(imageURL)
        onChange(imageURL) // instead of the default target.value        
    }

    const style =  {
        width: "200px",
        maxHeight: "200px",
        border: "2px lightgray dashed"
    }

    return (
        <>
        <Files
                multiple={false} 
                maxSize="15mb"
                accept={["image/jpg","image/jpeg",,"image/png"]}
                onSuccess={handleChange}
            >
                {({ browseFiles, getDropZoneProps, getLabelProps }: any) => (
                    <>
                    {file? (                            
                                <>
                                    <ImgDialog file={file} handleUpload={handleUpload} />                                
                                </>                            
                            
                        ) :
                        (<div
                            onClick={browseFiles}
                            {...getDropZoneProps({
                                style: { ...style, cursor: "pointer" }
                            })}
                        >
                            {fileURL && (
                                <img
                                    style={{ width: "100%" }}
                                    src={fileURL}
                                />
                            )}
                        </div>)
                    }
                    </>
                )}
            </Files>
             
             </>
            )
    
    
}

const PairInput: FC<PairInputProps> = (props) => {

    
    const classes = useStyles();
    const source = props.source
    const label = props.label
    if (!props.record) return null;

    
    return (
        <Paper elevation={3} className={classes.root}>
            
                <p>{label}</p>
                <Field name={source+".imageTop"} component={FileInputImageAdapter} label="Top Image" />
                <Field name={source+".imageBottom"} component={FileInputImageAdapter} label="Bottom Image" />
            
        </Paper>
    );
};

export default PairInput;
