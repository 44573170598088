import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { Login } from './layout';

import levels from './levels';
import countries from './countries';
import englishMessages from './i18n/en';




const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'fr') {
    //     return import('./i18n/fr').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {       
    //<Resource  options={{ label: 'countries' }} name="countries" {...countries} />
    return (
        <Admin
            title="Connect It"
            dataProvider={dataProvider}            
            authProvider={authProvider}
            loginPage={Login}
            i18nProvider={i18nProvider}
        >            
            <Resource  options={{ label: 'countries' }} name="countries" {...countries} />
            {/* <Resource  options={{ label: 'levels-ad-AD' }} name="levels/lang/ad/country/AD" {...levels} /> */}
            <Resource  options={{ label: 'levels-de-DE' }} name="levels/lang/de/country/DE" {...levels} />
            <Resource  options={{ label: 'levels-en-IV' }} name="levels/lang/en/country/IV" {...levels} />
            <Resource  options={{ label: 'levels-en-US' }} name="levels/lang/en/country/US" {...levels} />
            <Resource  options={{ label: 'levels-fr-FR' }} name="levels/lang/fr/country/FR" {...levels} />
            
        </Admin>
    );
};

export default App;