import * as React from 'react';
import { FC, Fragment } from 'react';
import {BulkDeleteButton, CreateButton, Pagination, TextField,  NumberField, Filter, List, Datagrid, DateField, EditButton, TextInput } from 'react-admin';

import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import BulkCloneButton from '../components/BulkCloneButton';
import SwapLevelButton from '../components/SwapLevelButton';
import PairDisplay from '../components/PairDisplay';
import CardActions from '@material-ui/core/CardActions';

import WithConfirm from '../components/WithConfirm';


const LevelBulkActionButtons = (props:any) => (
    <Fragment>
        <BulkCloneButton label="Clone Levels" {...props} />
        <WithConfirm><BulkDeleteButton {...props} /></WithConfirm>
    </Fragment>
);

const ButtonActions = ({ basePath, data, resource, total, maxResults, currentSort, filterValues, permanentFilter, permissions  }:any) => (
    <CardActions> 
        { permissions !== "guest" && 
        <>
        <SwapLevelButton basePath={basePath} resource={resource} key='2'/>
        <CreateButton basePath={basePath} key='3'/>
         </>
        }
    </CardActions>
);





const LevelPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />

const ListFilters = (props: any) => {
    
    return (
    <Filter {...props}>
        <TextInput source="ids" label="Id" alwaysOn/>
        <TextInput source="content" label="Content" alwaysOn/>
    </Filter>
)};


const RankCheck = (props:any) => {
    
    if(!(props?.record?.rankCheck)) {
        return (<Tooltip title="Discontinuité : niveau inaccessible !" aria-label="warning1"><WarningIcon style={{fontSize: 20, color: "#F00" }}/></Tooltip>)
    } else {
        return (<></>)
    }

}

const LikePercentage =  (props:any) => {

    if(props?.record?.likes > 1 || props?.record?.dislikes > 1) {
        let percent = props.record.likes / (props.record.likes + props.record.dislikes) * 100.0
        let total = props.record.likes + props.record.dislikes
        if(percent < 70) {
            return (<><p style={{color: "#F00" }}> {percent.toFixed(2)} % </p><p style={{color: "#F00", fontSize: 9}}> ({total} votes) </p></>)
        } else {
            return (<><p style={{color: "#0F0" }}> {percent.toFixed(2)} % </p><p style={{color: "#0F0", fontSize: 9}}> ({total} votes) </p></>)
        }
    } else return null
}


const LevelList = ({permissions, ...props}:any) => {
    return (        
        <List {...props} exporter={false} filters={<ListFilters />} pagination={<LevelPagination />} perPage={50} sort={{ field: 'rank', order: 'ASC' }} actions={<ButtonActions permissions={permissions}/>} bulkActionButtons={<LevelBulkActionButtons permissions={permissions}/>}>
            <Datagrid  rowClick="edit" optimized>            
                <NumberField source="rank" label="Index"/>            
                <RankCheck/>
                <TextField source="title" label="Title" /> 
                <PairDisplay/>
                <LikePercentage label="Likes percentage"/>
                <DateField source="createdAt" label="Modified"/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}


export default LevelList;
