import LevelIcon from '@material-ui/icons/DeveloperMode';
import GavelIcon from '@material-ui/icons/Gavel';

import LevelList from './LevelList';
import LevelCreate from './LevelCreate';
import LevelEdit from './LevelEdit';


export default {
    create: LevelCreate,
    edit: LevelEdit,
    list: LevelList,
    icon: LevelIcon,
};
